import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { AppPages } from 'pages';
import { AppUriPath } from 'app/AppRoutes';
import { Provider } from 'app/providers';
import { ToastContainer } from "react-toastify";
import { UnProtectedPage } from 'app/views/UnProtectedPage';
import { ProtectedPage } from 'app/views/ProtectedPage';

function App() {

  const { PlansPage, SignInPage, SignUpPage, AddonsPage, AccountPage, InvoicesPage, SubscriptionsPage,
    PageNotFound, InvoiceCardPage, VerifyEmailPage, TranslationKeys, CreateSubscription, UpdateSubscription,
    CheckoutPage, ChargesPage, CreateSinglePayment, SessionPage, WidgetsPage } = AppPages;

  return (<Provider>
    <ToastContainer theme="colored" pauseOnHover position="top-right" />
    <BrowserRouter>
      <Routes>
        <Route path={AppUriPath.signIn} element={<UnProtectedPage children={<SignInPage />} />} />
        <Route path={`${AppUriPath.session}`} element={<SessionPage />} />
        <Route path={`${AppUriPath.signUp}/:email?`} element={<UnProtectedPage hideFooter children={<SignUpPage />} />} />
        <Route path={`${AppUriPath.signUp}/:email/verify`} element={<UnProtectedPage hideFooter children={<VerifyEmailPage />} />} />
        <Route path={AppUriPath.subscriptions} element={<ProtectedPage children={<SubscriptionsPage />} />} />
        <Route path={AppUriPath.plans} element={<ProtectedPage children={<PlansPage />} />} />
        <Route path={AppUriPath.addons} element={<ProtectedPage children={<AddonsPage />} />} />
        <Route path={AppUriPath.charges} element={<ProtectedPage children={<ChargesPage />} />} />
        <Route path={AppUriPath.invoices} element={<ProtectedPage children={<InvoicesPage />} />} />
        <Route path={`${AppUriPath.invoices}/:invoiceId`} element={<ProtectedPage children={<InvoiceCardPage />} />} />
        <Route path={AppUriPath.account} element={<ProtectedPage children={<AccountPage />} />} />
        <Route path={AppUriPath.notfound} element={<PageNotFound />} />
        <Route path={AppUriPath.translations} element={<TranslationKeys />} />
        <Route path={`${AppUriPath.subscriptionSetup}/:data`} element={<ProtectedPage hideNavbar hideTopSideBar children={<CreateSubscription />} />} />
        <Route path={AppUriPath.subscriptionSetup} element={<ProtectedPage hideNavbar hideTopSideBar children={<CreateSubscription />} />} />
        <Route path={AppUriPath.onboarding} element={<ProtectedPage hideNavbar hideTopSideBar children={<CreateSubscription />} />} />
        <Route path={`${AppUriPath.subscriptions}/:subscriptionId`} element={<ProtectedPage hideNavbar hideTopSideBar children={<UpdateSubscription />} />} />
        <Route path={`${AppUriPath.subscriptions}/:subscriptionId/ad/:priceId`} element={<ProtectedPage hideNavbar hideTopSideBar children={<UpdateSubscription />} />} />
        <Route path={`${AppUriPath.createSinglePayment}/:chargeId/:currencyCode`} element={<ProtectedPage hideNavbar hideTopSideBar children={<CreateSinglePayment />} />} />
        <Route path={`${AppUriPath.widgets}`} element={<WidgetsPage />} />
        <Route path={`${AppUriPath.widgets_invoiceCard}/:invoiceId`} element={<InvoiceCardPage />} />
        <Route path={AppUriPath.checkout} element={<CheckoutPage />} />
        <Route path="*" element={<Navigate to={AppUriPath.plans} replace />} />
      </Routes>
    </BrowserRouter>
  </Provider>);
}

export default App;